<template>
  <div class="tabs-layout">
    <dynamic-tabs :activeName.sync="activeName" :tabs="tabs"></dynamic-tabs>
  </div>
</template>

<script>
import DynamicTabs from '../../../components/DynamicTabs.vue';

export default {
  name: 'RenewalIndex',
  components: {
    DynamicTabs,
  },
  data() {
    return {
      activeName: 'delay-wait-payment',
      tabs: [
        {
          label: '确认延期待签约付定金',
          component: 'delay-wait-payment',
        },
        {
          label: '已延期生效',
          component: 'carry-over-effect',
        },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>
